import React from 'react'
import { Container} from 'react-bootstrap';
import { Link } from "react-router-dom";
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';

const Donation = () => {
  return (
    <section id='donation' className='text-center py-4 py-md-5'>
        <h2>Donation</h2>
        <Container className='donate'>
            <p className='large-text'>You can help lots of people <br /> by your <span> little contribution </span></p>
            <p className='helper-text'>Donate to Seva Sadhana.</p>
            <Link className="btn-primary" to="/donate">Donate Now <DoubleArrowRoundedIcon fontSize='small'/></Link>
        </Container>
    </section>
  )
}

export default Donation
