import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container, Row } from 'react-bootstrap'
import Slider from '../components/Slider';
import About from '../components/About';
import Gallery from '../components/Gallery';
import Contact from '../components/Contact'
import Card from '../components/Card';
import Donation from '../components/Donation';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import image1 from '../assets/images/image1.png'
import image2 from '../assets/images/image2.png'
import image3 from '../assets/images/image3.png'

const Homepage = () => {
  const location = useLocation();
  const pathname= location.pathname
  const hash = location.hash
  const key = location.key
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div>
      <Slider />
      <About />
      <section id='gallery' className='text-center '>
          <Container className='gallery-container'>
              <h2 className='pt-5 pb-3'>Gallery</h2>
              <div className='d-flex align-items-center justify-content-center'>
                  <Gallery />
              </div>
              <div className='pb-5'><Link className="btn-primary" to="/">View All<DoubleArrowRoundedIcon fontSize='small'/></Link></div>
          </Container>
      </section>
      <section id="mediaCoverage" className="mediaCoverage pt-4 pt-md-5">
        <Container>
          <div>
              <h2 className='centertext'>Media Coverage</h2>
          </div>
          <Row>
          <Card title='Temple build By Seva Sadhna' imageSrc={image1} imageAlt='Temple build By Seva Sadhna' description='Three villages completely developed by seva sadhana, having a small temple.' />
          <Card title='Homes for homeless' imageSrc={image2} imageAlt='Homes for homeless' description='Seva Sadhana provides home to homeless in remote area. Built concrete house from Bhunga.' />
          <Card title='Bhitara Village' imageSrc={image3} imageAlt='Bhitara Village' description='Kutchi Village build by Seva Sadhana in remote area of kutch.' />
          </Row>
        </Container>
      </section>
      <Donation />
      <Contact />
    </div>  
  )
}

export default Homepage;