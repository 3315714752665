import React from 'react'
import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap'

const TermsandConditions = () => {
  return (
    <div className='termsandconditions'>
        <section id='donation-breadcrumb'>
            <Container>
                <div className='donation-breadcrumb'>
                    <nav aria-label="breadcrumb">
                    <h3 className='breadcrumb-heading'>Refund Policy</h3>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Refund Policy</li>
                    </ol>
                    </nav>
                </div>
            </Container>
        </section>
        <section>
            <Container>
                <div>
                    <p>Seva Sadhana believes in helping its customers as far as possible and has therefore a liberal cancellation policy. Under this policy:</p>
                    <p>In case you feel that the service received does not work as the site or not as per details provided by Us, you must bring it to the notice within 24
                         hours of receiving the refund. The customer service team after looking into your complaint will take an appropriate decision.</p>
                </div>
            </Container>
        </section>
        <section className="policy">
            <Container>
               <div animateIn="fadeInUp">
                   <h2 className="type-3">Refund Policy</h2>
                    <p>Seva Sadhana does not offer refunds or re-credits for use/scanned services. Service returns will only be considered based on technical issues with the services 
                        at the sole discretion of Seva Sadhana. The company reserves the right to first exercise resolving the technical issue that may include offline correction and provide services.</p>
                </div>
            </Container>
        </section>
   </div>
  )
}

export default TermsandConditions