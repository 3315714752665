import React from 'react'
import { Link } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
// import Button from '@mui/material/Button';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';


const About = () => {
  return (
    <section id='contact' className='pt-4 pt-md-5'>
    <Container className='pb-5'>
        <Row>
            <Col md={8} sm={12} className='know-us pt-3'>
                <h2 >Know Us</h2>
                <p className='registration-no'><span> Seva Sadhana Kutch </span><br /> <br /> [Trust Registration No. : F/3249/KutchGuj/2070/Kutch]</p>
                <Row className='g-0 pt-2'>
                    <Col md={1} sm={12} className='text-center'><LocationOnSharpIcon style={{ color:'#A41505'}} fontSize='medium' /></Col>
                    <Col><h6 className='address'> Seva Sadhana Karyalay<br />Nr. Santoshimata Mandir,<br />Kutch Kalyan Sang,<br />Bhuj Kutch.<br />370001<br />Gujarat - India</h6></Col>
                </Row>
                <Row className='g-0 pt-2'>
                    <Col md={1} sm={12} className='text-center'><a href="tel:+91 9428086509"><CallRoundedIcon style={{ color:'#A41505'}} fontSize='medium' /></a></Col>
                    <Col className='pt-3px'><h6><a href="tel:+91 9428086509"> +91 94280 86509</a></h6></Col>
                </Row>
                <Row className='g-0 pt-2'>                
                    <Col md={1} sm={12} className='text-center'><a href="mailto:sevasadhanabhuj@gmail.com"><MailRoundedIcon  style={{ color:'#A41505'}} fontSize='medium' /></a></Col>
                    <Col className='pt-3px'><h6><a href="mailto:sevasadhanabhuj@gmail.com">sevasadhanabhuj@gmail.com</a></h6></Col>                
                </Row>
            </Col>
            <Col md={4} sm={12} className='we-serve pt-3'>
                <h2>We Serve</h2>
                <ul className='services'>
                    <li className='service-item'><h6><Link to="#"> Tiffin Service</Link></h6></li>
                    <li className='service-item'><h6><Link to="#"> Avas Yojana</Link></h6></li>
                    <li className='service-item'><h6><Link to="#"> Kit Distribution</Link></h6></li>
                    <li className='service-item'><h6><Link to="#"> Covid-19 Service</Link></h6></li>
                    <li className='service-item'><h6><Link to="#"> સંસ્કાર સિંચન </Link></h6></li>
                    <li className='service-item'><h6><Link to="#"> Food Packet </Link></h6></li>
                </ul>
            </Col>
            <div className='text-center pt-4 pt-md-5'><Link className="btn-primary" to="/donate">Help us to reach more</Link>
            </div>
        </Row>
    </Container>
</section>   
  )
}

export default About
