import React, { useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,  
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homepage from './pages/Homepage';
import Donate from './pages/Donate'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ThankYou from './pages/ThankYou';
import TermsandConditions from './pages/TermsandConditions';
import RefundPolicy from './pages/RefundPolicy'
import LoadingOverlay from 'react-loading-overlay';
import './App.css';

function App() {

  const [loading,setLoading] = useState(false)

  const process = (value) => {
      setLoading(value)
  }
  return (
    <div>
      <BrowserRouter>
        <LoadingOverlay
              active={loading}
              spinner
              text='Donation Under Process, Please Wait....'
              >
          <Header />
            <Routes>
              <Route path="/" element={<Homepage />}></Route>
              <Route path="/donate" element={<Donate load={process} />}></Route>
              <Route path="/donate/thank-you" element={<ThankYou />}></Route>
              <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
              <Route path="/terms-and-conditions" element={<TermsandConditions />}></Route>
              <Route path="/refund-policy" element={<RefundPolicy />}></Route>
            </Routes>
          <Footer />
        </LoadingOverlay>
      </BrowserRouter>
    </div>
  )
}

export default App;
