import React from 'react'
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import image1 from '../assets/images/about-us.png';

const About = () => {

    // const handleform = () => {}
  return (
      <section id="about" className='py-5 py-md-5'> 
        <Container>
            <div id='about-us' className='flex'>
                <div className='parent-div-va flex-image'>
                    <img className='img-fluid about-image' alt='Seva Sadhana' src={image1} />
                </div>
                <div className='flex-content'>
                    <h2>About Us</h2>
                    <p>Seva Sadhna is NGO providing free support in remote areas of Kutch - Gujarat region.
                         Our NGO distribute Education Kit,provide health chekup, and run Skill enchancement programms. We are an 80G Govt Of India Registered Trust.
                         Seva Sadhana also Contributes in building homes in remote areas of kutch region. Currently 3 villages (Gorevalli, Luna, Bhittra) developement is completed by SEVA SADHANA.</p>
                    <p>Vision of Seva Sadhana is to support people in need. We find best suitbable place to donate. Let's come together and support people in need with your contribution.</p>
                    <Link className="btn-primary" to="/">Know More <DoubleArrowRoundedIcon fontSize='small'/></Link>
                </div>
            </div>
        </Container>
    </section>    
  )
}

export default About
