import React from 'react'
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';

const Card = (props) => {
    const cardTitle = props.title
    const imgSrc = props.imageSrc
    const imgAlt = props.imageAlt
    const description = props.description
    const moreLink = props.moreLink ? props.moreLink : null
    
  return (
            <Col sm={12} md={5} lg={4}>
                <div className="card">
                    <div> 
                        <img src={imgSrc} className='image-fluid' alt={imgAlt} />
                    </div>
                        <h4 className='title'>{cardTitle}</h4>
                        <p className="description">{description}</p>
                    <div className='text-right'>
                       {moreLink ? <Link className=" more-button " to={moreLink}>more <DoubleArrowRoundedIcon fontSize='1.2vw'/></Link> : null }
                    </div>
                </div>
            </Col>   
  )
}

export default Card
