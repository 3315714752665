import React from 'react';
import Slide1 from '../assets/images/banner-seva-sadhna.png';

const Slider = () => {
    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100" src={Slide1} alt="Seva Sadhana" />
                        <div className="carousel-caption">
                            <h5 className='sliderText'>We are here to help<br /> everyone in need</h5>
                        </div>
                </div>
            </div>
        </div>
      
    )
}

export default Slider;