import React from 'react'
import { Link } from "react-router-dom";
import image from '../assets/images/thankyou.svg'
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import { useLocation } from "react-router";

const ThankYou = () => {
  const state = useLocation();
  return (
      <div>  
        <h3 id="responsive-dialog-title">
        Donation Done Successfully !
        </h3>
        <DialogContent>
          <DialogContentText className='py-4 px-5 text-center'>
            <img src={image} className='img-fluid d-block m-auto my-4' alt='Thank you for donation | Seva Sadhana' style={{width:'250px'}}/>
            Your receipt will be sent to you via email.
             If you Want to download your receipt, <a href={`${state.state.path}`} rel="noreferrer" target="_blank">Click here</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus>
          <Link className="btn-primary-light" to='/'>OK</Link>
          </Button>
          <Button autoFocus>
          <Link className="btn-primary" to="/donate">Another Donation <DoubleArrowRoundedIcon fontSize='small'/></Link>
          </Button>
        </DialogActions>
    </div>
  )
}

export default ThankYou
