import React, { useState }  from 'react';
import { Link } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ReactWhatsapp from 'react-whatsapp';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Logo from '../assets/images/logo.png';
import { Container} from 'react-bootstrap';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

const Header = () => {
    const pagePath = true
    const menuItems = [
        {
            label: 'Home',
            path: '/',
            icon: '',
        },
        {
            label: 'Gallery',
            path: '/#gallery',
            icon: '',
        },
        {
            label: 'Media',
            path: '/#mediaCoverage',
            icon: '',
        },
        {
            label: 'Donation',
            path: '/#donation',
            icon: '',
        },
        {
            label: 'Contact',
            path: '/#contact',
            icon: '',
        }
    ]
    const [sideMenu, setSideMenu] = useState(false)
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setSideMenu({ sideMenu, 'left': open });
    };
    return (
        <div id="header">
        <section className='top-header-section d-none d-md-block'>
            <Container className='top-header-container'>
                <div className='g-0 top-header'>
                    <div className='top-header-div'>
                    <a href="mailto:sevasadhanabhuj@gmail.com"><EmailIcon fontSize='medium' /> sevasadhanabhuj@gmail.com</a> | <a href="tel:+91 9428086509"><CallIcon fontSize='medium' /> +91 94280 86509 </a>
                    </div>
                    <div className='top-header-div'>
                    <ReactWhatsapp className='whatsappIcon' number="+919428086509"><WhatsAppIcon fontSize='medium'/></ReactWhatsapp>|&nbsp;
                    <a href="https://www.facebook.com/profile.php?id=100044128129278" target='_blank' rel="noreferrer"><FacebookIcon fontSize='medium'/></a>&nbsp;|&nbsp;
                    <a href="https://www.youtube.com/channel/UCy1-24CMnHK9JSGjSiPPt7w" target='_blank' rel="noreferrer"><YouTubeIcon fontSize='medium'/> </a>
                    </div>
                </div>
            </Container>
        </section>
        <Container className='header-bottom'>
            {/* Mobile Menu */}
            <SwipeableDrawer
                open={sideMenu['left']}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Box
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    className='navigation-links'
                >
                    <img src={Logo} alt="Seva Sadhana Logo" className="logo-img p-3 mx-5" />
                    <Divider />
                    <List>
                        {menuItems.map((menu, index) => (
                            <Link key={index}  to={menu.path}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <KeyboardReturnIcon className="fa-flip-horizontal" />
                                    </ListItemIcon>
                                    <ListItemText primary={menu.label} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </Box>
            </SwipeableDrawer>
            {/* END: Mobile Menu */}
            <Link to="/">
                <img src={Logo} alt="Seva Sadhana Logo"  className="logo-img"/>
            </Link>
            <Button onClick={toggleDrawer(true)} className="d-block d-md-none menuicon"><MenuIcon /></Button>
            <nav id="navbar" className="navbar d-none d-md-block">
                <ul>
                    {menuItems.map((menu, index) => {
                        return (
                            <li key={index}>
                                <Link to={menu.path} className={pagePath === false ? 'nav-link current-page' : 'nav-link'}>{menu.label}</Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </Container>
    </div>
    );
}

export default Header;