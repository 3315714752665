import React from 'react'
import { Link } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap'
import Logo from '../assets/images/logo.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import ReactWhatsapp from 'react-whatsapp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';

const Footer = () => {
    return (
        <div>
        <div id="footer" className='text-white'>
            <section  className='footerTop'>
                <Container>
                    <Row>
                        <Col md={3} xs={7}>
                            <img src={Logo} className="logo-img" alt='Seva Sadhana '></img>
                            <p>Seva Sadhna is NGO that provides free support in remote areas of Kutch - Gujarat region.</p>
                            <ul className='socialIcons'>
                            <li><a href="https://www.facebook.com/profile.php?id=100044128129278" target='_blank' rel="noreferrer"><FacebookIcon className='icon' /> </a></li>
                            <li><ReactWhatsapp className='whatsappIcon' number="+919428086509"><WhatsAppIcon fontSize='medium'/></ReactWhatsapp></li>
                            <li><a href="https://www.youtube.com/channel/UCy1-24CMnHK9JSGjSiPPt7w" target='_blank' rel="noreferrer"><YouTubeIcon className='icon' /> </a></li>
                            </ul>
                        </Col>
                        <Col md={2} xs={5} >
                            <h5>Seva</h5>
                            <ul>
                                <li><h6><Link to="/donate">Tiffin Service</Link></h6></li>
                                <li><h6><Link to="/donate">Avas Yojana</Link></h6></li>
                                <li><h6><Link to="/donate">Kit Distribution</Link></h6></li>
                                <li><h6><Link to="/donate">Covid-19 Service </Link></h6></li>
                            </ul>
                        </Col>
                        <Col md={4} xs={7} className='text-white footer-contact'>
                            <h5>Contact</h5>
                            <Row className='g-0 pt-2'>
                                <Col xs={2} className='text-center' ><LocationOnSharpIcon style={{color:'white'}} fontSize='small' /></Col>
                                <Col><h6> Seva Sadhana Karyalay<br />Nr. Santoshimata Mandir,<br />Kutch Kalyan Sang,<br />Bhuj Kutch.<br />370001<br />Gujarat - India</h6></Col>
                            </Row>
                            <Row className='g-0 pt-2'>
                                <Col xs={2} className='text-center'><a href="tel:+91 9428086509"><CallRoundedIcon style={{color:'white'}} fontSize='small' /></a></Col>
                                <Col className='pt-3px'><h6><a href="tel:+91 9428086509"> +91 94280 86509</a></h6></Col>
                            </Row>
                            <Row className='g-0 pt-2'>                
                                <Col xs={2} className='text-center'><a href="mailto:sevasadhanabhuj@gmail.com"><MailRoundedIcon style={{color:'white'}} fontSize='small' /></a></Col>
                                <Col className='pt-3px'><h6><a href="mailto:sevasadhanabhuj@gmail.com">sevasadhanabhuj@gmail.com</a></h6></Col>                
                            </Row>
                        </Col>
                        <Col md={3} xs={5}>
                            <h5>Information</h5>
                            <ul>
                                <li><h6><Link to="/privacy-policy">Privacy Policy</Link></h6></li>
                                <li><h6><Link to="/terms-and-conditions">Terms of Use </Link></h6></li>
                                <li><h6><Link to="/refund-policy">Refund Policy</Link></h6></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                </section>
                </div>
                <section>
                <div className="copyright">
                        <p>&copy; {new Date().getFullYear()} Seva Sadhana Kutch. All rights reserved.
                        Website designed and developed by Arkay Apps.</p>
                </div>
                </section>
            </div>
        
    )
}

export default Footer