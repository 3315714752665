import React from 'react'
import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap'

const TermsandConditions = () => {
  return (
    <div className='termsandconditions'>
        <section id='donation-breadcrumb'>
            <Container>
                <div className='donation-breadcrumb'>
                    <nav aria-label="breadcrumb">
                    <h3 className='breadcrumb-heading'>Terms and Conditions</h3>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Terms and conditions</li>
                    </ol>
                    </nav>
                </div>
            </Container>
        </section>
        <section className="policy">
            <Container>
               <div animateIn="fadeInUp">
                   <h2 className="type-3">Terms of use</h2>
                   <p>These terms of use (“Terms”) govern the access, browsing and use by the users (“User” or “Users”, as applicable) of this website run by Seva Sadhana referred
                     to below; as well as the services rendered through the Website (“Service” or “Services”,
                      as applicable) which include th use of certain content.</p>
                   <p>Accessing and using the Website implies that the User has read and accepts to be bound by these Terms without exception. In case the User does not accept
                     the Terms or have any objection to any part of the present Terms the User must not use the Website.</p>
                   <p>Seva Sadhana may modify the Terms at any time and thus we recommend that the Terms are reviewed on a regular basis by the User. The date at the beginning
                     of these Terms refers to the latest update of these Terms, which will be applicable from the date of publication.</p>
                   <p>Some Services provided through the Website may be subject to specific conditions or instructions that must be accepted by the User prior to the provision of the 
                    relevant Service. These specific conditions may be imposed by Seva Sadhana or by third parties. Such specific conditions shall apply in addition to the Terms and in case of 
                    conflict shall supersede the Terms. Accordingly, the User must read and accept such specific conditions before the provision of the relevant Service.</p>
                   <p>Likewise, in respect of the collection and processing of personal data, the <Link to='/privacy-policy'>Privacy Policy</Link> will apply.</p>
               </div>
            </Container>
        </section>
        <section className='bg-accent'>
            <Container >
               <div animateIn="fadeInUp">
                   <h2 className="type-3">(1) Authorise use of website</h2>
                   <p>User is only authorized to use the Website and the Services in accordance with the Terms and in good faith. In particular and without limitation Users
                     undertake that any access or downloads of any content available in the Website or through the Services will always be the result of a genuine legitimate 
                     interest of the User and acknowledges that any method which artificially increases the number of downloads, accesses or clicks over such content is strictly 
                     prohibited under this Terms and will result in the cancellation of the User’s account by Seva Sadhana and the obligation for the User to indemnify Seva Sadhana for all damages suffered as a 
                     result of the User’s breach of this undertaking.</p>
                   <p>The User agrees not to use the Services negligently, for fraudulent purposes or in an unlawful manner. Likewise, the User agrees not to partake in any conduct or action that could
                     damage the Designs, Image, interests or rights of the Website or third parties, particularly, the owners of the Collaborators Content, the Third Party Content or the Sponsored Content.</p>
                   <p>The User will not interfere with the functioning of the Website or in the Services, in particular will not impersonate another user or person. The User agrees not to carry out any 
                    action that may damage, make unavailable, overload, deteriorate or impede the normal use of the Website or the Services, which may impact the security of the Website or the Services,
                    or which may in any way interfere with the Services offered by Seva Sadhana. It is prohibited to use robots, spiders or any other mechanism, mobile application, program or tool to a
                    ccess, copy or control any part of the Website or the Services in any way which is contrary to the ordinary use of the Website or which infringes Seva Sadhana’s interests, without its 
                    express prior authorization. Likewise, it is prohibited to obtain or attempt to obtain the contents of the Website using any method or system not expressly authorized by Seva Sadhana or 
                    which is not the ordinary method of accessing the Website.</p>
               </div>
            </Container>
        </section>
        <section>
            <Container>
               <div animateIn="fadeInUp">
                   <h2 className="type-3">(2) Responsibility</h2>
                   <p>The User acknowledges and agrees that uses the Website and its Services at the User’s own risk and under the User’s responsibility and therefore Seva Sadhana does not a
                    ccept any responsibility for misuse or use in breach of these Terms.</p>
                   <p>The User will be responsible for any damages to Seva Sadhana resulting from the User’s use of the Website and the Services in breach of the Terms and accepts to indemnify 
                    Seva Sadhana and its directors, employees, agents and representatives from any liability in which they may incur resulting from the User’s breach of these Terms.</p>
                   <p>Seva Sadhana does not warrant the availability or continuity of the Website and the Services, neither its reliability, quality, completeness, accuracy or whether they are 
                    fit for a specific purpose or activity.</p>
                   <p>As way of example and without limitation, Seva Sadhana shall not be liable for any damages that may result from:</p>
                   <p><mark><i>SAVE FOR ONLINE OCR CONVERTOR WILFUL MISCONDUCT, AND THOSE CASES IN WHICH DUE TO THE SPECIFIC CIRCUMSTANCES OF THE USER INVOLVED OR THE NATURE OF THE MATTER, APPLICABLE
                     LAW PROVIDES THAT LIABILITY CANNOT BE LIMITED BY AGREEMENT, USE OF THE Website AND THE SERVICES IS AT SOLE RISK OF THE USER AND www.onlineocrconverter.com SHALL NOT BE LIABLE FOR ANY 
                     DAMAGE OF ANY KIND CAUSED TO THE USER AS A RESULT OF USING THE Website AND/OR THE SERVICES.</i></mark> </p>
                   <p>In accordance with Section 4 above regarding Third Party Content and Sponsored Content, Seva Sadhana acts exclusively as a provider of a search service as requested by the 
                    User, without assuming any responsibility for search results.</p>
                   <p>The insertion of links in the Services does not imply any relationship, recommendation or supervision by Seva Sadhana of the linked website and accordingly, Seva Sadhana
                     does not accept any liability in relation to the content of any linked website except in the specific circumstances provided by applicable law.</p>
                   <p>In accordance with Section 4 above, regarding the Collaborator Contents, Seva Sadhana acts exclusively as the provider of the intermediation service between the offer of 
                    Collaborator Contents and the Users interested in downloading them, without any liability in relation to such contents, except for those cases expressly stated by Law.</p>
                   <p>Seva Sadhana will make all reasonable commercial efforts in order to ensure the accuracy of keywords and descriptions, as well as the identification of Content as exclusively 
                    for editorial use or any equivalent expression. However, Seva Sadhana <mark><i>NEITHER WARRANTS NOR MAKES ANY REPRESENTATION REGARDING ANY KEYWORD, TITLE OR DESCRIPTION; OR THE 
                    IDENTIFICATION OR LACK OF IDENTIFICATION OF ANY VISUAL CONTENT AS EXCLUSIVELY FOR EDITORIAL USE.</i></mark> For this reason and notwithstanding any other liability limitation that may be
                    applicable in accordance with these Terms, Seva Sadhana shall neither indemnify nor assume any liability in connection with any claim resulting from inaccurate keywords, titles or 
                    descriptions, or from the use of the visual content identified as exclusively for editorial use.</p>
               </div> 
            </Container>
        </section>
        <section className='bg-accent'>
            <Container>
               <div animateIn="fadeInUp">
                   <h2 className="type-3">(3) General And Contact Information</h2>
                   <p>The use of the Website and the Services shall be governed by Indian Law. Except for those cases in which applicable regulations impose a specific jurisdiction, any dispute in 
                    connection with these Terms shall be resolved by the Courts of Gujarat, and the Parties waive expressly any other applicable jurisdiction.</p>
                   <p>If any provision in these Terms is declared to be invalid or unenforceable, it shall be deemed that it had not been included. The remaining provisions in these Terms shall not 
                    be affected in any way.</p>
                   <p>You may contact Seva Sadhana for any query or claim at:</p>
                    <a href='mailto:sevasadhanabhuj@gmail.com'>sevasadhanabhuj@gmail.com</a>
               </div>   
            </Container>
       </section> 
    </div>
  )
}

export default TermsandConditions