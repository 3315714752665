import React, {useState, useEffect} from 'react'
import { Container } from 'react-bootstrap'
import { Link, useNavigate} from "react-router-dom";
import { useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {API_PASSWORD, API_USERNAME } from '../config'
import {apiPost} from '../functions'

const Donate = (props) => {
    const location = useLocation();
    const pathname= location.pathname
    const hash = location.hash
    const key = location.key
    useEffect(() => {
      // if not a hash link, scroll to top
      if (hash === '') {
        document.getElementById("body").scrollTo(0,0);
      }
      // else scroll to id
      else {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    }, [pathname, hash, key]); // do this on route change
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [states,setStates] = useState([])
    const [state,setState] = useState({})
    const [donationTypes,setDonationTypes] = useState([])
    const [donation,setDonation] = useState({})
    const [idProofs,setIdProofs] = useState([])
    const [idProof,setIdProof] = useState(null)
    const [idProofNo,setIdProofNo] = useState('')

    const [formData, setFormData] = useState(
        {fullName: "", email: "", mobileNumber: '', city:'', pinCode:'', address:'', amount: null, panNumber: null})

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
            }
        setShowAlert(false);
    }
        
    const handleChange = (event) => {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    useEffect(() => {
        getStates()
        getdonationCauses()
        getIdProofs()
        // eslint-disable-next-line
    }, [])

    const token = {
        "username":API_USERNAME,
        "password":API_PASSWORD,
    }
    const getStates = () => {
        apiPost('/api/v1/get_state_list', token)
        .then((res) => {
            setStates(res.data.data)
            res.data.data.map((item)=>{
                if(item.name=== 'Gujarat')return setState(item)
                else return null
            })
        })
        .catch((err) => {
            console.log(err);
            setAlertType('error')
            setAlertMessage(err)
            setShowAlert(true)
        });
    }
    const getdonationCauses = () => {
        apiPost('/api/v1/get_donation_causes_list', token)
        .then((res) => {
            setDonationTypes(res.data.data)
            setDonation(res.data.data[0])
        })
        .catch((err) => {
            console.log(err);
            setAlertType('error')
            setAlertMessage(err)
            setShowAlert(true)
        });
    }

    const getIdProofs = () => {
        apiPost('/api/v1/get_idproofs_list_get', token)
        .then((res) => {
            setIdProofs(res.data.data)
        })
        .catch((err) => {
            console.log(err);
            setAlertType('error')
            setAlertMessage(err)
            setShowAlert(true)
        });
    }

    const handleIdProofChange = (value) => {
        setIdProof(value)
        setIdProofNo('')
    }        

    const onFormSubmit = (e) => {
        e.preventDefault()
        if(formData.amount >= 10000){
            if(formData.panNumber){
                displayRazorpayPaymentSdk()
            }else{
                setAlertType('error')
                setAlertMessage('PAN Card is mandatory for donations above 10000 rupees')
                setShowAlert(true)
            }
        }else {
            if((formData.panNumber) || (idProof && idProofNo)){
                    displayRazorpayPaymentSdk()
            }else {
                setAlertType('error')
                setAlertMessage('PAN Card or any other ID Proof from available options is required')
                setShowAlert(true)
            }
        }
    }

    const loadRazorpayScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpayPaymentSdk() {
        const res = await loadRazorpayScript("https://checkout.razorpay.com/v1/checkout.js");  
        if (!res) {
            setAlertType('error')
            setAlertMessage('Razorpay SDK failed to load. please check are you online?')
            setShowAlert(true)
            return;
        }else{
            const params = {
                "username":API_USERNAME,
                "password":API_PASSWORD,
                "full_name" : formData.fullName ? formData.fullName : null ,
                "email" : formData.email ? formData.email : '',
                "state_id" : state ? state.id : null,
                "city" : formData.city ? formData.city : null,
                "pincode" : formData.pinCode ? formData.pinCode : null,
                "mobile_number" : formData.mobileNumber ? formData.mobileNumber : null,
                "address" : formData.address ? formData.address : null,
                "donation_cause_id" : donation ? donation.id : null,
                "merchant_amount" : formData.amount ? formData.amount : null,
                "idproof_type_id" : idProof ? idProof.id : 1,
                "idproof_number" : idProofNo ? idProofNo : null,
                "pan_number" : formData.panNumber,
                "redirection_url" : window.location.href +"/thank-you"
            }
            const url = '/api/v1/razorpay_order'
            apiPost(url,params)
            .then((res) => {
                const {merchantId=null , amount=null, currency=null,orderId=null } = res.data.data;
                const options = {
                    key: merchantId,
                    amount: amount,
                    currency: currency,
                    name: "Seva Sadhana",
                    description: orderId.toString(),
                    // image: { logo },
                    // order_id: "Order-5152",
                    "handler": function (response){
                        props.load(true)
                        const data = response.razorpay_payment_id
                        apiPost('/api/v1/razorpay_callback',data)
                        .then((res) => {
                            navigate('/donate/thank-you',{state:{path:res.data}})
                            props.load(false)
                        })
                        .catch((err) => {
                            console.log(err);
                            setAlertType('error')
                            setAlertMessage(err)
                            props.load(false)
                            setShowAlert(true)
                        });
                    },
                    prefill: {
                    name: formData.fullName,
                    email: formData.email,
                    contact: formData.mobileNumber,
                },
                    theme: {    
                        color: "#a41505",
                    },
                };
            
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
            });
        }
    }

  return (
    <div className='bg-accent'>
        <Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={showAlert}
			autoHideDuration={5000}
			onClose={handleAlertClose}
		>
            <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                {alertMessage}
            </Alert>
		</Snackbar>
        <section id='donation-breadcrumb'>
            <Container>
                <div className='donation-breadcrumb'>
                    <nav aria-label="breadcrumb">
                    <h3 className='breadcrumb-heading'>Donation</h3>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Donation</li>
                    </ol>
                    </nav>
                </div>
            </Container>
        </section>
        <section id='bank-details'>
            <Container className='bank-details-container'>
                <div>
                    <h3 className='section-heading'>Our Bank Details</h3>
                </div>
                <hr style={{color:'#F18220', width:'80%',height:2, position:'relative', left:'10%', margin:0}} />
                <div className='bank-details-content'>
                    Seva Sadhna Kutch is registered under the Trust Act 1950:E/2365/Kutch <br /> 
                    All donations are exempted under section 80G of Income Tax Act,1961 subject to receipt of PAN.<br />
                    Pan Card Number : AAWTS6860N <br />
                    8G No: AAWTS6860NF20218 <br />
                    Bank Details : Axis Bank, Bhuj Branch <br />
                    Account Name : Seva Sadhana <br />
                    Account Number : 9200 1005 1156 923 <br />
                    IFSC CODE : UTIB 0000 829
                </div>
                <hr style={{color:'#F18220', width:'80%',height:2, position:'relative', left:'10%', margin:0}} />
            </Container>
        </section>
        <section id='donation-form'>
            <form onSubmit={onFormSubmit}>
                <Container  className='bank-details-container'>
                    <Card >
                        <CardContent>
                            <Grid
                                className='mt-5'
                                container
                                spacing={3}
                                direction="row"
                            >
                                <Grid item xs={12}>
                                    <div>
                                            <b className='h4'>Indian Resident Donor </b>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>Full Name *</span>
                                    </div>
                                </Grid> 
                                <Grid item xs={12} className='mt-n4 input-field'>
                                <TextField 
                                    required
                                    variant='outlined'
                                    fullWidth 
                                    value={formData.fullName}
                                    name='fullName'
                                    onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>Email *</span>
                                    </div>
                                </Grid> 
                                <Grid item xs={12} className='mt-n4 input-field'>
                                    <TextField
                                    required
                                    variant='outlined'
                                    type='email'
                                    name='email'
                                    value={formData.email}
                                    fullWidth
                                    onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>Mobile Number *</span>
                                    </div>
                                </Grid> 
                                <Grid item xs={12} className='input-field mt-n4'>
                                    <TextField 
                                    required
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    name='mobileNumber'
                                    inputProps={{maxLength: 15,minLength:10}}
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>State *</span>
                                    </div>
                                </Grid> 
                                <Grid item xs={12} className='mt-n4 input-field'>
                                <Autocomplete
                                    required
                                    options={states}
                                    name='state'
                                    autoHighlight
                                    value={state}
                                    getOptionLabel={(option) => option.name || ''}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    onChange={(e,value) => {setState(value)}}
                                />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <div>
                                        <span>City *</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className='mt-n4 input-field'>
                                    <TextField 
                                    required
                                    fullWidth
                                    variant='outlined'
                                    name='city'
                                    value={formData.city}
                                    onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>Pin Code</span>
                                    </div>
                                </Grid> 
                                <Grid item xs={12} className='mt-n4 input-field'>
                                    <TextField 
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    name='pinCode'
                                    value={formData.pinCode}
                                    onChange={handleChange}
                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div>
                                        <span>Address</span>
                                    </div>
                                </Grid> 
                                <Grid item xs={12} className='mt-n5 input-field'>
                                <TextareaAutosize
                                    className='text-area'
                                    maxRows={4}
                                    aria-label="maximum height"
                                    fullWidth
                                    name='address'
                                    value={formData.address}
                                    onChange={handleChange}
                                    style={{width:'100%',height:85}}
                                />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
                <Container className='bank-details-container'>
                    <Card>
                        <CardContent>
                            <Grid
                                className='mt-5'
                                container
                                spacing={3}
                                direction="row"
                                >
                                <Grid item xs={12}>
                                    <div className='flex'>
                                        <b className='h4'>Donation Detail</b>
                                        {/* eslint-disable-next-line */}
                                        <b className='h4 mr-auto'>Donation Amount : {formData.amount && formData.amount != 0 ? formData.amount + '/- INR' : 0}</b>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>Donation Type *</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className='mt-n4 input-field'>
                                <Autocomplete
                                    required
                                    options={donationTypes}
                                    autoHighlight
                                    value={donation}
                                    getOptionLabel={(option) => option.name || ''}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    onChange={(e, value) => {setDonation(value)}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>Donation Amount *</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className='mt-n4 input-field'>
                                    <TextField
                                    required
                                    variant='outlined'
                                    type='number'
                                    name='amount'
                                    value={formData.amount}
                                    fullWidth
                                    onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <span>PAN Card Number{formData.amount >= 10000 ? ' *' : null }</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className='mt-n4 input-field'>
                                    <TextField 
                                    fullWidth
                                    variant='outlined'
                                    type='string'
                                    name='panNumber'
                                    inputProps={{maxLength: 10,minLength:10}}
                                    value={formData.panNumber}
                                    onChange={(e)=>{handleChange(e);handleIdProofChange(null)}}
                                    />
                                </Grid>
                                {(formData.panNumber) || !(formData.amount < 10000) ? null :
                                <>
                                    <Grid item xs={12}>
                                        <div>
                                            <span>ID Proof Type</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className='mt-n4 input-field'>
                                    <Autocomplete
                                        options={idProofs}
                                        autoHighlight
                                        value={idProof}
                                        getOptionLabel={(option) => option.name || ''}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        onChange={(e,value) => handleIdProofChange(value)}
                                    />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>
                                            <span>ID Proof Number</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className='mt-n4 input-field'>
                                    <TextField 
                                        disabled={idProof ? false : true}
                                        fullWidth
                                        variant='outlined'
                                        value={idProofNo}
                                        onChange={(e)=>setIdProofNo(e.target.value)}
                                        />
                                    </Grid>
                                </>
                                }
                        </Grid>
                        <Grid item xs={12} className='mt-4'>
                                <div>
                                    <p className='danger'><b className='h6'>Note:</b> PAN Number is mandatory for donations above 10000 rupees, <br /> For donations below 10000 any one document proof is required.</p>
                                </div>
                            </Grid>
                        <Grid item xs={12} className="text-center mt-5">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Proceed to Pay
                            </Button>
                        </Grid>					
                    </CardContent>
                </Card>
            </Container>
        </form>
        </section>
    </div>  
)}

export default Donate;