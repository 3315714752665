import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Im4 from '../assets/images/image4.png';
import Im5 from '../assets/images/image5.png';
import Im6 from '../assets/images/image6.png';
import Im7 from '../assets/images/image7.png';
import Im8 from '../assets/images/image8.png';
import Im9 from '../assets/images/image9.png';

export default function Gallery() {
  return (
    <Box sx={{ width: 900 }}>
      <ImageList variant="masonry" cols={3} gap={2}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: Im4,
    title: 'Bed',
  },
  {
    img: Im5,
    title: 'Books',
  },
  {
    img: Im6,
    title: 'Sink',
  },
  {
    img: Im7,
    title: 'Kitchen',
  },
  {
    img: Im8,
    title: 'Blinds',
  },
  {
    img: Im9,
    title: 'Chairs',
  },
  
];
